@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300");

// Set:
$family-san-serif: "Nunito Sans","Segoe UI", "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", "Verdana", "Verdana Ref", sans-serif;

//$family-serif: "Anaheim", "Georgia", sans-serif;
//$family-serif: "Cutive Mono", monospace;

// Use:
$family-primary: $family-san-serif;
