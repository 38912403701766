// SET the color vars...
$primary: #c71941;
$primary-invert: findColorInvert($primary);
$secondary: #873349;
$secondary-invert: findColorInvert($secondary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$amazon: #ebaa0a;
$amazon-invert: findColorInvert($amazon);
$paypal: #1b29c2;
$paypal-invert: findColorInvert($paypal);

// Links
$link: #0e67ff;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Notifications
.notification {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Tables
.table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.section {
  background-color: #f1f4ff;
}

// USE $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
        "white": ($white, $black),
        "black": ($black, $white),
        "light": ($light, $light-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert),
        "secondary": ($secondary, $secondary-invert),
        "info": ($info, $info-invert),
        "success": ($success, $success-invert),
        "warning": ($warning, $warning-invert),
        "danger": ($danger, $danger-invert),
        "twitter": ($twitter, $twitter-invert),
        "amazon": ($amazon, $amazon-invert),
        "paypal": ($paypal, $paypal-invert)
);
